import React from 'react';
import { connect} from 'react-redux';
import  {Container} from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles'
import BasePage from '../common/base'
import {Constants} from '../../constants/Constants';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { UIAction, InventoryActions, UserActions, TenantsActions } from '../../redux/actions';
import Moment from "moment";
import { extendMoment } from "moment-range";
import { SanitizeArray } from "../../hooks/functions";
import CurrentInvenotryComponent from './currentInvenotry.component';
import { CustomDateFormat } from '../../hooks/functions';
import { constructEnqueueParams } from '../../helpers/report-queue.helper';
import { FilterOption } from '../../redux/models/common/ui';

const moment = extendMoment(Moment as any);

const Inventory = (props:any) => {

  const columns = () => {
  return [
    {
      "label": "Product",
      "name": "productLabelName",
      "options": {
        "display": true,
      }
    },
    {
      "label": "NDC",
      "name": "ndc",
      "options": {
        "display": true,
      }
    },
    {
      "label": "Lot",
      "name": "lot",
      "options": {
        "display": true,
      }
    },
    {
      "label": "On hand",
      "name": "onHandQty",
      "options": {
        "display": true,
      }
    },
    {
      "label": "Expiration",
      "name": "expiration",
      "options": {
        "display": false,
        "viewColumns": true,
        "customBodyRender": (value?: any) => {
          return CustomDateFormat(value, 'YYYY-MM-DD');
        }
      }
    },
    {
      "label": "Customer",
      "name": "customerName",
      "options": {
        "display": true,
      }
    },
    {
      "label": "Address",
      "name": "address",
      "options": {
        "display": false,
        "viewColumns": true,
      }
    },
    {
      "label": "City",
      "name": "city",
      "options": {
        "display": true,
      }
    },
    {
      "label": "State",
      "name": "state",
      "options": {
        "display": true,
      }
    },
    {
      "label": "Zip Code",
      "name": "postcode",
      "options": {
        "display": true
      }
    },
    {
      "label": "GPO",
      "name": "gpo",
      "options": {
        "display": false,
        "viewColumns": true
      }
    },
    {
      "label": "IDN",
      "name": "idn",
      "options": {
        "display": false,
        "viewColumns": true
      }
    },
    {
      "label": "Class of Trade",
      "name": "classOfTradeDescription",
      "options": {
        "display": false,
        "viewColumns": true
      }
    },
    {
      "label": "Facility Type",
      "name": "facilityTypeDescription",
      "options": {
        "display": false,
        "viewColumns": true
      }
    }
  ];
};

  const classes = DashboardStyles();
  const [columnsOrder, setColumns] = React.useState(columns());
  const [includeWarehouse, setIncludeWarehouse] = React.useState(false);
  const [currentInventoryFetched, setCurrentInventoryFetched] = React.useState(false)
  const [pagination, setPaginationData] = React.useState({
    offset: 0,
    limit: 10,
    page: 0
  });

  const todayDate = moment();
  const dateRangesList = [
    {
      label: "Next 7 days",
      value: "Next_7_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(7, "days")),
    },
    {
      label: "Next 15 days",
      value: "Next_15_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(15, "days")),
    },
    {
      label: "Next 30 days",
      value: "Next_30_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(30, "days")),
    },
    {
      label: "Next 60 days",
      value: "Next_60_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(60, "days")),
    },
    {
      label: "Next 180 days",
      value: "Next_180_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(180, "days")),
    },
    {
      label: "Current Month",
      value: "Current_Month",
      date: moment.range(
        moment().clone().startOf("month").add(1, "days"),
        moment().clone().endOf("month")
      ),
    },
  ];

  const handleSearch = () => {
    const filter: any = {
      offset: pagination.offset,
      limit: pagination.limit,
      fields: {
        productItemId: true,
        productLabelName: true,
        ndc: true,
        lot: true,
        expiration: true,
        customerName: true,
        address: true,
        city: true,
        state: true,
        postcode: true,
        gpo: true,
        idn: true,
        classOfTradeDescription: true,
        facilityTypeDescription: true,
      },
      where: generateFiltersList(),
    };

    if (!props.selectedSupplier) {
      return;
    }

    let payload = [{ key: "filter", value: JSON.stringify(filter) }];
    if (props.selectedFilterValues["REGION"])
      payload.push({
        key: "regionName",
        value: props.selectedFilterValues["REGION"],
      });
    if (props.selectedFilterValues["CUSTOMER_STATE"])
      payload.push({
        key: "state",
        value: props.selectedFilterValues["CUSTOMER_STATE"],
      });

    props.getLotLevelInventory(payload);
    setCurrentInventoryFetched(true);
  };

  const generateFiltersList = () => {
    const filter: any = {};
    const supplier = props.selectedSupplier;
    const filterValues = props.selectedFilterValues || {};
    const productsArrayForQuery: any[] = [];
    const productsSelected: any[] = filterValues["PRODUCT_NAME"] || [];

    if (productsSelected?.length) {
      productsSelected.forEach((item: any) => {
        productsArrayForQuery.push(item.productGroup);
      });
    }

    filter.supplierId = supplier ? supplier.id : undefined;
    filter.supplierCode = supplier ? supplier.supplierCode : undefined;
    filter.productGroup = productsArrayForQuery?.length
      ? productsArrayForQuery
      : undefined;
    filter.lot = filterValues["LOT_NUMBER"] || undefined;

    const dateData: any = props.selectedExpiryDate
      ? dateRangesList.find((d) => {
          return d.value === props.selectedExpiryDate;
        })
      : null;

    filter.expiration = dateData?.date
      ? {
          between: [
            `${dateData.date.start.format("YYYY-MM-DD")}`,
            `${dateData.date.end.format("YYYY-MM-DD")}`,
          ],
        }
      : undefined;
    filter.gpo = filterValues["GPO"] || undefined;
    filter.idn = filterValues["IDN"] || undefined;
    filter.classOfTradeDescription = filterValues["CLASS_OF_TRADE"] || undefined;
    filter.facilityTypeDescription = filterValues["FACILITY_TYPE"] || undefined;

    filter.state = filterValues["CUSTOMER_STATE"] || undefined;
    filter.region = filterValues["REGION"] || undefined;

    return filter;
  };

  const handleInventoryExport = () => {
    let filter: any = {
      plantInventory: includeWarehouse,
      where: generateFiltersList(),
      fields: { 
        productItemId: true, 
        productLabelName: true, 
        lot: true, 
        state: true, 
        postcode: true, 
        city: true, 
        customerId: true, 
        customerName: true, 
        ndc: true, 
        expiration: true 
      }
    };

    const userTimezoneProp = props.userProperties.find((property: any) => {
      return property.propertyKey === 'TIMEZONE'
    });

    const tz = userTimezoneProp?.propertyValue || moment.tz.guess();
    const colNames: any[] = [];

    columnsOrder.forEach((c: any) => {
      colNames.push(c.name);
    });


    const params = constructEnqueueParams(
      'SUP_UIEXPORT_InventoryReport',
      props.authUser?.record.email,
      filter,
      {columnsRequested: colNames},
      tz
    );

    props.sendOneTimeReportRequest(params);
  }

  React.useEffect(() => {
    handleSearch();
  }, [pagination]);

  React.useEffect(() => {
    props.getFilterData({
      filterList: [
        {
          name: 'CLASS_OF_TRADE', 
          params: props.selectedSupplier ? { supplierId: props.selectedSupplier.supplierCode } : {}
        }, 
        {
          name: 'FACILITY_TYPE', 
          params: props.selectedSupplier ? { supplierId: props.selectedSupplier.supplierCode } : {}
        }
      ],
      callback: (responseUrl: string, responseData: any) => {
        switch(responseUrl){
          case 'classOfTradeOptions':
            props.setClassOfTradeOptions(responseData)
            break
          case 'facilityTypeOptions':
            props.setFacilityTypeOptions(responseData)
            break
        } 
      }
    })
  }, [props.selectedSupplier]);

  return (
		<BasePage 
      title={Constants.PAGE_TITLE.INVENTORY} 
      drawerOpen={true}
      pageNameKey="inventory"
    >
			<div className={classes.appBarSpacer}></div>
			<Container maxWidth={false} className={classes.container}>
				<GlobalFiltersComponentContainer
					pageName="INVENTORY"
					executeSearch={handleSearch}
					onReset={() => {
						props.resetAllFilters();
						props.resetExpiryDate();
					}}
					doExport={handleInventoryExport}
				/>
				<CurrentInvenotryComponent
					columnsOrder={columnsOrder}
					setColumns={setColumns}
					pagination={pagination}
					columns={columns}
					setPaginationData={setPaginationData}
					currentInventoryFetched={currentInventoryFetched}
				/>
			</Container>
		</BasePage>
  );
}
const mapStateToProps = (state: any) => ({
  selectedSupplier: state.ui.selectedSupplier || null,
  selectedFilterValues: state.ui.selectedFilterValues || {},
  selectedExpiryDate: state.ui.selectedExpiryDate || null,
  suppliersList: state.supplier?.suppliersList?.result || [],
  locationList: (state.location.list && state.location.list[0]) ? SanitizeArray(state.location.list[0]) : [],
  authUser: state.user.authUser,
  userProperties: state.user.user_properties || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  resetExpiryDate: ()  => dispatch(UIAction.setSelectedExpiryDate(null)),
  getInventoryList: (filter: any) => dispatch(InventoryActions.getInventoryList(filter)),
  getLotLevelInventory: (filter: any) => dispatch(InventoryActions.getLotLevelInventory(filter)),
  clearInventoryList: () => dispatch(InventoryActions.clearInventoryList()),
  setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
  getRegionInventory: (filter: any) => dispatch(InventoryActions.getRegionGroupInventory(filter)),
  clearRegionInventory: (empty: any) => dispatch(InventoryActions.successRegionGroupInventory(empty)),
  getExportInventory: (filter: any) => dispatch(InventoryActions.getExportInventory(filter)),
  showLoader: (isLoader: boolean) => dispatch(UIAction.showLoader(isLoader)),
  sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)) ,
  setClassOfTradeOptions: (data: string[]) => dispatch(TenantsActions.setClassOfTradeOptions(data)),
  setFacilityTypeOptions: (data: string[]) => dispatch(TenantsActions.setFacilityTypeOptions(data)),
  getFilterData: (data: FilterOption[]) => dispatch(UIAction.getFilterData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
