import * as React from 'react';
import { MuiThemeProvider, Modal, TextField, Button, FormControl, Chip, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { UserRecord } from '../../redux/models/user/user';
import MUIDataTable from 'mui-datatables';
import AddIcon from '@material-ui/icons/Add';
import { UsersTableTheme, UsersStyles } from './users.styles';
import UserForm from './user-form.component';
import AuthUser from '../../redux/lib/authUser';
import { Claims } from '@fffenterprises/authorization/lib/userAuthorization';
import { UserDetails } from './user-details';
import FilterList from "../../components/filterList"
import { FixViewColumns } from "../../hooks/functions";
import ClearIcon from "@material-ui/icons/Clear";
import Loading from '../../components/loading';
import { Constants } from "../../constants/Constants";
import EmptyContent from '../../components/emptyContent';
import { Supplier } from '../../components/autoComplete';
import { getMuiTheme } from '../../hooks/styles';
import { getSelectedSupplier, CustomDateFormat } from "../../hooks/functions";
import AdvanceFilters from "../../components/advanceFilters";
import { List } from "../../constants/Constants";
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import moment from 'moment';

export class FiltersSave {
  supplierId?: string;
  supplierCode?: string;
  firstNameFilter?: string;
  lastNameFilter?: string;
  emailFilter?: string;
}
export interface FiltersSaveType {
  supplierId?: string;
  supplierCode?: string;
  firstNameFilter?: string;
  lastNameFilter?: string;
  emailFilter?: string;
}

interface UsersProps {
  authUser?: AuthUser
  usersList?: UserRecord[]
  isLoading: boolean
  selectedSupplier: any
  getUsersList: (payload: any | null) => void
  setSelectedSupplier: (customer: any) => void
  resetAllFilters: () => void
  suppliersList: any[]
  totalUsers?: number;
  selectedFilterValues: any
}

export const UsersComponent: React.FC<UsersProps> = (props) => {

  const [modalOpen, setModalOpen] = React.useState(false);
  const [userToOpen, setUserToOpen] = React.useState({} as UserRecord);
  const classes = UsersStyles();
  let [selectedSupplier, setSelectedSupplier] = React.useState(null as any);
  let [firstNameFilter, setFirstNameFilter] = React.useState('');
  let [lastNameFilter, setLastNameFilter] = React.useState('');
  let [emailFilter, setEmailFilter] = React.useState('');
  let [componentKey, setComponentKey] = React.useState('1');
  const [isFilterReset, setFilterReset] = React.useState(false);
  const [isFilterList, setFilterListStatus] = React.useState(false);
  let [checked, setChecked] = React.useState(['']);
  const [filterPayload, setFilterPayload] = React.useState([] as any);
  let [filterToSave, setFilterToSave] = React.useState<FiltersSaveType | null>(null);
  let [savedSupplierCode, setSavedSupplierCode] = React.useState(null as any);
  const [sortOrder, setSortOrder] = React.useState('');
  const [pagination, setPaginationData] = React.useState({
    offset: 0,
    limit: 10,
    page: 0
  });
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    if (props.suppliersList.length > 0) {
      if (isFirstRender.current && !props.selectedSupplier) {
        props.setSelectedSupplier(props.suppliersList[0]);
        isFirstRender.current = false;
      }
    }

  }, [props.suppliersList]);

  const handleTenantSelect = (event: any, value: any) => {
    if (!value) {
      setSelectedSupplier(null);
      props.setSelectedSupplier(null);
      return;
    }
    if (value === 'ALL') {
      setSelectedSupplier('ALL');
      props.getUsersList(null);
      return;
    }
    setSelectedSupplier(value);
    props.setSelectedSupplier(value);
    //window.history.pushState({}, '', `/users/${value.supplierCode}`)
  }

  const reinitiatePagination = () => {
    setPaginationData({
      offset: 0,
      limit: 10,
      page: 0
    });
  }
  const handleUsersSearch = (supplierId?: any) => {
    let payload: any = [];


    if (props.selectedSupplier) {
      payload.push({
        key: 'tenantId',
        value: props.selectedSupplier.supplierCode
      })
    }

    const filterValues = props.selectedFilterValues || {};
 
    if (filterValues['USER_FIRST_NAME']) {
      payload.push({
        key: 'firstName',
        value: filterValues['USER_FIRST_NAME']
      })
    }

    if (filterValues['USER_LAST_NAME']) {
      payload.push({
        key: 'lastName',
        value: filterValues['USER_LAST_NAME']
      })
    }

    if (filterValues['USER_EMAIL']) {
      payload.push({      
        key: 'email',
        value: filterValues['USER_EMAIL']
      })
    }

    if (sortOrder) {
			payload.push({
				key: 'order',
				value: sortOrder,
			});
		}

    payload.push({
      key: 'offset',
      value: pagination.offset,
    })

    payload.push({
      key: 'limit',
      value: pagination.limit,
    })

    setFilterPayload(payload);
    props.getUsersList(payload);
  }

  const renderTenants = (value: Claims | null, tableMeta: any, updateValue: any) => {
    if (!value || Object.keys(value).length === 0) {
      return '';
    }
    return (
      <div>
        {Object.keys(value).map((tenant, index) => {
          return (<Chip className={classes.chip} key={index} label={`${tenant}`} />)
        })}
      </div>
    )
  }

  const openUserModal = (rowData: string[], rowMeta: { rowIndex: number, dataIndex: number }) => {
    if (!props.usersList) {
      return;
    }
    const user = props.usersList[rowMeta.dataIndex];
    setUserToOpen(user);
    setModalOpen(true);
  }

  const getUserModal = () => {
    if (props.authUser?.authorizationCheck({
      action: 'update',
      resource: 'userManagement',
      tenantId: selectedSupplier?.supplierCode ?? 'ALL'
    })) {
      return (
        <UserForm filterPayload={filterPayload} authUser={props.authUser} thisUser={userToOpen} close={handleModalClose} />
      )
    }
    return (
      <UserDetails close={handleModalClose} thisUser={userToOpen} />
    )
  }

  const handleModalOpen = () => {
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setUserToOpen({} as UserRecord);
    setModalOpen(false);
  };

  const handleFilterList = () => {
    setFilterListStatus(!isFilterList);
    setFilterReset(false);
  }
  const onClickAway = () => {
    setFilterListStatus(false);
  }
  const onFilterChecked = (filterList: any) => {
    setChecked(filterList.checkedList);
    if (filterList.checkedValue === 'FIRST_NAME') {
      setFirstNameFilter('');
    }
    if (filterList.checkedValue === 'LAST_NAME') {
      setLastNameFilter('');
    }
    if (filterList.checkedValue === 'EMAIL') {
      setEmailFilter('');
    }

  }

  const handleFilterChange = (value: any, type: string) => {
    if (type === 'FIRST_NAME') {
      setFirstNameFilter(value);
    }
    else if (type === 'LAST_NAME') {
      setLastNameFilter(value);
    } else if (type === 'EMAIL') {
      setEmailFilter(value);
    }
  }

  const resetAllFilter = () => {
    setFirstNameFilter('');
    setLastNameFilter('');
    setEmailFilter('');
    setComponentKey(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
    setFilterReset(true);
    setChecked([]);
    props.setSelectedSupplier(null);
    setSelectedSupplier(null);
    setSavedSupplierCode(null)
  }

  const clearInputField = (filterName: string) => {
    if (filterName === 'FIRST_NAME') {
      setFirstNameFilter('');
    }
    else if (filterName === 'LAST_NAME') {
      setLastNameFilter('');
    } else if (filterName === 'EMAIL') {
      setEmailFilter('');
    }
  }


  const generateDataList = (data: any[]) => {
    const dataList: any[] = [];
    if (data) {
      data.forEach((item: any, key: number) => {
        let tenantsString = '';
        if (item.claims && Object.keys(item.claims).length > 0) {
          let claimsObj = Object.keys(item.claims);
          claimsObj.forEach((tenant, index) => {
            tenantsString += tenant;
            if (index < (claimsObj.length) - 1) {
              tenantsString += ',';
            }
          });
        }
        item.tenantsString = tenantsString;
        dataList.push(item);
      })
    }
    return dataList;
  }


  const handleUsersSearchCallback = React.useCallback(handleUsersSearch, []);
  const handleTenantSelectCallback = React.useCallback(handleTenantSelect, []);

  React.useEffect(() => {
    if (props.selectedSupplier) {
      handleTenantSelectCallback(null, props.selectedSupplier);

      if (props.selectedSupplier !== selectedSupplier) {
        handleUsersSearchCallback(props.selectedSupplier.supplierCode);
      }
    }
  }, [props.selectedSupplier, handleUsersSearchCallback, handleTenantSelectCallback, selectedSupplier]);


  React.useEffect(() => {
    const supplierCode = (selectedSupplier && selectedSupplier.supplierCode) ? selectedSupplier.supplierCode : null;
    handleUsersSearch(supplierCode);
  }, [pagination, sortOrder]);

  const tableCols = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        display: true,
        sortOrder: null,
        change: false
      }
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
        display: true,
        sortOrder: null,
        change: false
      }
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true,
        display: true,
        sortOrder: null,
        change: false
      }
    },
    {
      name: 'claims',
      label: 'Suppliers',
      options: {
        customBodyRender: renderTenants,
        download: false,
        change: true,
        sort: false
      }
    },
    {
      name: 'tenantsString',
      label: 'Suppliers',
      options: { filter: false, display: false, viewColumns: false }
    }
  ]
  const [tableColumns, setColumns] = React.useState(tableCols);

  return (
    <div>
      {!props.authUser ? <div><Loading message="" /></div> :
        <div>
          <div className={classes.customerSearchWrap}>
            <Grid container>
              <Grid item xs={12}>
                <GlobalFiltersComponentContainer 
                  pageName="USERS" 
                  executeSearch={handleUsersSearch}
                  onReset={() => {props.resetAllFilters() }}
                />
                
                {props.authUser?.authorizationCheck({ action: 'create', resource: 'userManagement', tenantId: selectedSupplier ? selectedSupplier.supplierCode : 'ALL' }) &&
                  <FormControl className={classes.formControl}>
                    <Button
                      id="create-new-button"
                      variant="contained"
                      color="secondary"
                      onClick={handleModalOpen}>
                      Create New User
                    </Button>
                  </FormControl>
                }
              </Grid>
            </Grid>
          </div>

          {(!props.usersList || props.usersList.length === 0) && <EmptyContent message="Select the filter criteria above to view associated users." />}

          {(props.usersList && props.usersList.length > 0 && !props.isLoading) &&
          
              <MUIDataTable
                data={(props.usersList) ? generateDataList(props.usersList) : []}
                columns={tableColumns}
                options={{
                  rowsPerPageOptions: List.PageLimitOptions,
                  filterType: 'multiselect',
                  selectableRows: 'none',
                  onRowClick: openUserModal,
                  filter: false,
                  onColumnViewChange: (changedColumn: string, direction: string) => {
                    setColumns(FixViewColumns(changedColumn, direction, tableColumns));
                  },
                  serverSide: true,
                  count: (props.totalUsers),
                  pagination: (props.totalUsers && props.totalUsers > 10) ? true : false,
                  page: pagination.page,
                  rowsPerPage: pagination.limit,
                  onTableChange: (tableAction: any, tableState: any) => {
                    switch (tableAction) {
                      case 'sort':
                        setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
                        break;
                      case 'changeRowsPerPage':
                        setPaginationData({ offset: 0, limit: tableState.rowsPerPage, page: 0 });
                        break;
                      case 'changePage':
                        if (tableState.page > pagination.page) {
                          setPaginationData({ offset: pagination.offset + pagination.limit, limit: pagination.limit, page: tableState.page });
                        }
                        else if (tableState.page < pagination.page) {
                          setPaginationData({ offset: pagination.offset - pagination.limit, limit: pagination.limit, page: tableState.page });
                        }
                        break;
                    }
                  },
                  onDownload: () => {
                    const timezone = moment.tz.guess();
                    const dateFormat = 'YYYY-MM-DD';
                    let payload = [...filterPayload]
                    payload.push({
                      key: 'csvExport',
                      value: JSON.stringify({ timezone: timezone, dateFormat: dateFormat }),
                    });
                    props.getUsersList(payload);
                    return false;
                  },
                }}
              />
           
          }
          <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              {getUserModal()}
            </div>
          </Modal>
        </div>
      }
    </div>
  )
}