import React from 'react';
import {
  Button,
  Dialog,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input
} from '@material-ui/core';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { CommonStyles } from '../../../hooks/styles';
import { DialogTitle, DialogContent, DialogActions } from './dialogProps';
import { ScheduleFilterProps } from './models';
import { SaveSearchActions } from '../../../redux/actions';
import { useStyles } from '../styles';
import { List } from '../../../constants/Constants';
import SelectBox from '../../selectBox';
const ScheduleFilter = (props: ScheduleFilterProps) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    MuiListItem: {
      padding: 0
    }
  };
  const [frequency, setFrequency] = React.useState<string[]>([]);
  const frequencies = List.ScheduleReportFrequency;
  const [open, setOpen] = React.useState(false);
  const classes = CommonStyles();
  const searchClasses = useStyles();
  const [showError, setShowError] = React.useState(false);
  const [selectedFileType, setSelectedFileType] = React.useState<string>('pdf');
  React.useEffect(() => {
    setShowError(false);
    setOpen(props.open);
    setFrequency(props.frequency);
    setSelectedFileType(props.fileType);
  }, [props.open]);

  const handleClose = () => {
    props.handleClose();
  };
  const handleSave = () => {
    setShowError(false);
    let payload: any = {};
    let frequencyList = frequency.join(',');
    payload.uid = props.uuid;
    payload.fileType = selectedFileType;
    payload.frequency = (frequencyList.includes('NEVER_SCHEDULE')) ? "" : frequencyList;
    props.scheduleSearch(payload);
    setShowError(true);
  }
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, value: any) => {
    let selectedValues: any = (event && event.target && event.target.value) ? event.target.value : [];
    if (value?.props?.value == 'NEVER_SCHEDULE') {
      setFrequency(['NEVER_SCHEDULE'] as string[]);
    } else {
      let neverIndex = selectedValues.findIndex((val: any) => val == 'NEVER_SCHEDULE');
      if (neverIndex > -1) {
        selectedValues.splice(neverIndex, 1);
      }
      setFrequency(selectedValues as string[]);
    }
  };
  const getRenderValue = (selected: string[]) => {
    let selectedVal = selected.map((item: string) => {
      return (item.split('_')[0]) ? item.split('_')[0] : '';
    })
    return selectedVal.join(',');
  }

  React.useEffect(() => {
    if (props.error && props.error.severity != 'error') {
      setShowError(false);
      props.handleClose();
    }
  }, [props.error]);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Schedule Search
        </DialogTitle>
        <DialogContent dividers className={searchClasses.scheduleDialogContent}>
          <FormGroup row className={searchClasses.saveSearchFormGroup}>
            <FormControl className={`${classes.formControl} ${searchClasses.filterFormControl}`} >

              <TextField disabled id="pageName" label="Page Name" defaultValue={props.pageName} />
            </FormControl>
          </FormGroup>

          <FormGroup row className={searchClasses.saveSearchFormGroup}>
            <FormControl className={`${classes.formControl} ${searchClasses.filterFormControl}`}>
              <TextField disabled id="pageName" label="Search Name" defaultValue={props.title} />
            </FormControl>
          </FormGroup>

          <FormGroup row className={searchClasses.saveSearchFormGroup}>
            <FormControl className={`${classes.formControl} ${searchClasses.filterFormControl}`}>
              <TextField disabled id="email" label="Email" defaultValue={props.authUser?.record?.email} />
            </FormControl>
          </FormGroup>

          <FormGroup row className={searchClasses.saveSearchFormGroup}>
            <FormControl className={`${classes.formControl} ${searchClasses.filterFormControl}`}>
              <InputLabel id="frequency-checkbox-label">Frequency</InputLabel>
              <Select
                labelId="frequency-checkbox-label"
                id="frequency"
                multiple
                value={frequency}
                onChange={(event: any, value: any) => handleChange(event, value)}
                input={<Input />}
                renderValue={(selected: any) => getRenderValue(selected)}
                MenuProps={MenuProps}
              >
                {frequencies.map((name: any) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={frequency.indexOf(name.replace(/\s+/g, ' ').trim()) > -1} />
                    <ListItemText primary={name.split('_')[0]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
          {(frequency.findIndex((item)=>item == 'NEVER_SCHEDULE') < 0) &&
            <FormGroup row className={searchClasses.saveSearchFormGroup}>
              <FormControl className={`${classes.formControl} ${searchClasses.filterFormControl}`}>
                <SelectBox
                  componentId={'file-type'}
                  selected={selectedFileType}
                  inputLabel={'File Type'}
                  emptyItemLabel={'Choose'}
                  listItems={[{ label: 'CSV', value: 'csv' }, { label: 'PDF', value: 'pdf' }]}
                  onChangeItem={(value: any) => {
                    setSelectedFileType(value);
                  }
                  } />
              </FormControl>
            </FormGroup>
          }

          {(props.error && props.error.severity == 'error' && showError) && <p className={searchClasses.saveSearchError}>{props.error.message}</p>}
        </DialogContent>
        <DialogActions className={searchClasses.dialogActions}>
          <Button
            onClick={handleSave}
            type="button"
            className={classes.defaultButton}
            size="small"
            variant="contained"
            color="primary"
          >Save</Button> <Button
            onClick={handleClose}
            type="button"
            size="small"
            variant="contained"
            color="secondary"
          >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state: any) => (
  {
    authUser: state.user.authUser,
    error: (state.saveSearch && state.saveSearch.error) ? state.saveSearch.error : null
  }

);

const mapDispatchToProps = (dispatch: any) => ({
  scheduleSearch: (paylaod: any) => dispatch(SaveSearchActions.scheduleSaveRequest(paylaod)),
  getAdvanceSearch: (filter: any) => dispatch(SaveSearchActions.getAdvanceSearch(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleFilter);