import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
//import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from '../../hooks/styles';
import { Waypoint } from "react-waypoint";
import Loading from '../../components/loading';
import { ReportActions, UIAction } from "../../redux/actions";
import { Scrollbars } from 'react-custom-scrollbars';
import { CustomDateFormat } from '../../hooks/functions/CustomDateFormat';
import { Constants, List } from "../../constants/Constants";
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const InventoryList = (props: any) => {

  const classes = useStyles();


  const customBodyRenderFunction = (value?: any, tableMeta?: any, updateValue?: any) => {
    if ('expiration' === tableMeta.columnData.name) {
      return CustomDateFormat(value, Constants.DATE_FORMAT);
    }
    else if ('onHnadQty' === tableMeta.columnData.name) {
      const rowIndex = tableMeta.rowIndex;
      if (rowIndex === props.expiryData.length - 1) {
        return (
          <React.Fragment>
            <Waypoint
              onEnter={() => {
                /*if (props.totalInventoryCount > inventoryData.length) {
                  fetchData();
                }*/
              }}
            />
            {value}
          </React.Fragment>
        );
      } else {
        return <React.Fragment>{value}</React.Fragment>;
      }
    }
    else if ('productName' === tableMeta.columnData.name) {

      return (<div>
        <div>{value}</div>
        {
          (tableMeta.rowData[4]) &&
          <div>({tableMeta.rowData[4]})</div>
        }

      </div>);
    } else {
      return value;
    }
  }

  const getColumns = () => {

    const columns = [
      /* 0 */
      {
        label: "Product",
        name: "productLabelName",
      },
      /* 1 */
      {
        label: "NDC",
        name: "ndc",
      },
       /* 2 */
      {
        label: "Lot",
        name: "lot"
      },
       /* 3 */
      {
        label: "On hand",
        name: "onHandQty"
      },
       /* 4 */
      {
        label: "Expiration",
        name: "expiration",
        options: {
          sort: true
        }
      },
      /* 5 */
      {
        label: "Customer",
        name: "customerName",
      },
      /* 6 */
      {
        label: "Address",
        name: "address"
      },
      /* 7 */
      {
        label: "City",
        name: "city"
      },
      /* 8 */
      {
        label: "State",
        name: "state"
      },
      /* 9 */
      {
        label: "Zip Code",
        name: "postcode"
      },
      /* 10 */
      {
        label: "GPO",
        name: "gpo",
      },
      /* 11 */
      {
        label: "IDN",
        name: "idn",
      },
      /* 12 */
      {
        label: "Class of Trade",
        name: "classOfTradeDescription",
      },
      /* 13 */
      {
        label: "Facility Type",
        name: "facilityTypeDescription",
      }
 
    ];
    let columnsList = columns.map((item, index) => {
      return {
        name: item.name,
        label: item.label,
        options: {
          display: true,
          customBodyRender: customBodyRenderFunction,
          sort: true
        }
      }
    });

    columnsList[6].options.display = false;
    columnsList[10].options.display = false;
    columnsList[11].options.display = false;
    columnsList[12].options.display = false;
    columnsList[13].options.display = false;
    return columnsList;
  }

  const options = {
    search: false,
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: true,
    sort: true,
    count: 0,
    responsive: "vertical",
    fixedHeader: true,
    pagination: true,
    rowsPerPageOptions: List.PageLimitOptions,
    onTableChange: (tableAction: any, tableState: any) => {
      // console.log('tableAction', tableAction)
    },
  };
  /*<MuiThemeProvider theme={getMuiTheme({ tableHeadBackground: '#f1f1f1!important', tableHeadCellFontSize: '12px', paddingTop: '3px', paddingBottom: '3px', cellPadding: '4px!important', responsiveScroll: { maxHeight: 'unset!important', 'overflow': 'unset' } })} >*/

  return (
    <div className={classes.root}>
      {props.isMiniLoading && !props.isLoading ? <Loading message='Loading...' /> :
        <MUIDataTable
          data={props.expiryData}
          columns={getColumns()}
          options={options}
        />
      }
    </div>
  );
}

const mapStateToProps = (state: any) => (
  {
    test: state.productExpiration,
    expiryData: (state.productExpiration && state.productExpiration.expiryDatalist && state.productExpiration.expiryDatalist.result) ? state.productExpiration.expiryDatalist.result : [],
    isMiniLoading: state.ui.isMiniLoading,
    isLoading: state.ui.isLoading,
  }
);

const mapDispatchToProps = (dispatch: any) => ({
  //getInventoryList: (filter: any) => dispatch(ReportActions.getDrawerData(filter)),
  //showMiniLoader: (isLoader: boolean) => dispatch(UIAction.showMiniLoader(isLoader))
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryList);