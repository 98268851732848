import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles'
import BasePage from '../common/base'
import EmptyContent from '../../components/emptyContent';
import HeatChartSection from './graphSection';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { UIAction, ProductActions, ProductExpirationAction, TenantsActions } from '../../redux/actions';
import { CustomDateFormat } from "../../hooks/functions";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { FilterOption } from '../../redux/models/common/ui';
const moment = extendMoment(Moment as any);

const ProductExpiration = (props: any) => {

  const classes = DashboardStyles();
  let [expiryRange, setExpiryRange] = React.useState<any>(null);
  const [searchPayload, setSearchPayload] = React.useState<any>(null);

  const todayDate = moment();
  const dateRangesList = [
    {
      label: "Next 7 days",
      value: "Next_7_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(7, "days")),
    },
    {
      label: "Next 15 days",
      value: "Next_15_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(15, "days")),
    },
    {
      label: "Next 30 days",
      value: "Next_30_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(30, "days")),
    },
    {
      label: "Next 60 days",
      value: "Next_60_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(60, "days")),
    },
    {
      label: "Next 180 days",
      value: "Next_180_days",
      date: moment.range(todayDate.clone(), todayDate.clone().add(180, "days")),
    },
    {
      label: "Current Month",
      value: "Current_Month",
      date: moment.range(
        moment().clone().startOf("month").add(1, "days"),
        moment().clone().endOf("month")
      ),
    },
  ];

  const handleProductExpirationSearch = () => {
    const supplier = props.selectedSupplier;

    //setValidationRequired(true);
    if (!supplier) {
      return;
    }

    const filterValues = props.selectedFilterValues || {};
    const productGroup: any[] = [];
    const selectedProductsArray = filterValues["PRODUCT_NAME"] || [];

    if (selectedProductsArray?.length) {
      selectedProductsArray.forEach((item: any) => {
        productGroup.push(item.productGroup);
      });
    }

    //setValidationRequired(false);

    const bigQueryFilter: any = {};

    if (supplier) {
      bigQueryFilter.supplierId = supplier.supplierCode;
    }

    if (productGroup.length) {
      bigQueryFilter.productGroup = productGroup;
    }

    const expiryDateFormat: string = "YYYY-MM-DD";
    const dateData: any = props.selectedExpiryDate
      ? dateRangesList.find((d) => {
          return d.value === props.selectedExpiryDate;
        })
      : null;

    //filter.expiration = (dateData?.date) ? { "from": `${dateData.date.start.toISOString()}`, "to": `${dateData.date.end.toISOString()}` } : undefined;
    bigQueryFilter.gpo = filterValues["GPO"] || undefined;
    bigQueryFilter.idn = filterValues["IDN"] || undefined;
    bigQueryFilter.lotNumber = filterValues["LOT_NUMBER"] || undefined;
    bigQueryFilter.classOfTradeDescription = filterValues["CLASS_OF_TRADE"] || undefined;
    bigQueryFilter.facilityTypeDescription = filterValues["FACILITY_TYPE"] || undefined;

    const selectedInventoryType: string = "2"; //hardcode to consignment as that is the only data we have

    let payload = [];
    if (bigQueryFilter.supplierId)
      payload.push({ key: "supplierId", value: bigQueryFilter.supplierId });
    if (selectedInventoryType)
      payload.push({ key: "consignmentType", value: selectedInventoryType });
    if (props.selectedFilterValues["REGION"])
      payload.push({
        key: "regionName",
        value: props.selectedFilterValues["REGION"],
      });
    if (bigQueryFilter.productGroup)
      payload.push({ key: "productGroup", value: bigQueryFilter.productGroup });
    
    if (bigQueryFilter.lotNumber) {
      payload.push({ key: "lotNumber", value: bigQueryFilter.lotNumber }); 
    }
    if (bigQueryFilter.gpo)
      payload.push({ key: "gpo", value: bigQueryFilter.gpo });
    if (bigQueryFilter.idn)
      payload.push({ key: "idn", value: bigQueryFilter.idn });
    if (bigQueryFilter.classOfTradeDescription)
      payload.push({ key: "classOfTradeDescription", value: bigQueryFilter.classOfTradeDescription });
    if (bigQueryFilter.facilityTypeDescription)
      payload.push({ key: "facilityTypeDescription", value: bigQueryFilter.facilityTypeDescription });
    if (dateData)
      payload.push({
        key: "from",
        value: moment(dateData.date.start.clone()).format("YYYY-MM-DD"),
      });
    if (dateData)
      payload.push({
        key: "to",
        value: moment(dateData.date.end.clone()).format("YYYY-MM-DD"),
      });

    setSearchPayload(payload);

    props.getExpiryDatalist(payload);
    props.getExpiryGraphData({ params: payload, showLoader: true });
  };

  React.useEffect(() => {
    props.getFilterData({
      filterList: [
        {
          name: 'CLASS_OF_TRADE', 
          params: props.selectedSupplier ? { supplierId: props.selectedSupplier.supplierCode } : {}
        }, 
        {
          name: 'FACILITY_TYPE', 
          params: props.selectedSupplier ? { supplierId: props.selectedSupplier.supplierCode } : {}
        }
      ],
      callback: (responseUrl: string, responseData: any) => {
        switch(responseUrl){
          case 'classOfTradeOptions':
            props.setClassOfTradeOptions(responseData)
            break
          case 'facilityTypeOptions':
            props.setFacilityTypeOptions(responseData)
            break
        } 
      }
    })
  }, [props.selectedSupplier]);

  return (
		<BasePage 
      title="Product Expiration" 
      drawerOpen={true}
      pageNameKey="expiry"
    >
			<Container maxWidth={false} className={classes.container}>
				<div className={classes.appBarSpacer}></div>
				{/*<SearchBox setExpiryRange={setExpiryRange} />*/}

				<GlobalFiltersComponentContainer
					pageName="PRODUCT_EXPIRATION"
					executeSearch={handleProductExpirationSearch}
					onReset={() => {
						props.resetAllFilters();
					}}
				/>

        {props.productExpiration?.expiryGraphData?.length ? (
          <HeatChartSection
            expiryRange={expiryRange}
            searchPayload={searchPayload}
          />
        ) : (
          <EmptyContent message="Select the filter criteria above to view product expiry information."></EmptyContent>
        )}
      </Container>
    </BasePage>
  );
}
const mapStateToProps = (state: any) => ({
      total: state.dashboard?.dashboardDataItems?.total || [],
      productExpiration: state.productExpiration,
      selectedSupplier: state.ui.selectedSupplier || null,
      suppliersList: state.supplier?.suppliersList?.result || [],
      productList: state.product.productList || [],
      selectedFilterValues: state.ui.selectedFilterValues || {},
      selectedExpiryDate: state.ui.selectedExpiryDate || null
  });

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  getProductList: (supplierId: any) => dispatch(ProductActions.getProductList({
    supplierId: supplierId
  })),
  getExpiryDatalist: (filter: any) => dispatch(ProductExpirationAction.getExpiryDatalist(filter)),
  getExpiryGraphData: (filter: any) => dispatch(ProductExpirationAction.getExpiryGraphData(filter)),
  setClassOfTradeOptions: (data: string[]) => dispatch(TenantsActions.setClassOfTradeOptions(data)),
  setFacilityTypeOptions: (data: string[]) => dispatch(TenantsActions.setFacilityTypeOptions(data)),
  getFilterData: (data: FilterOption[]) => dispatch(UIAction.getFilterData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductExpiration);

