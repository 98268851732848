import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { connect } from 'react-redux';
import { TenantsActions, TransactionActions, UIAction, UserActions } from '../../redux/actions';
import MUIDataTable from 'mui-datatables';
import { CommonStyles } from '../../hooks/styles';
import { formatCabinetTypeForReport } from '../../hooks/functions/GetCabinetDisplayName';
import EmptyContent from '../../components/emptyContent';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import Moment from 'moment';
import { cloneDeep } from 'lodash';
import { extendMoment } from "moment-range";
import { constructEnqueueParams } from '../../helpers/report-queue.helper';
import { FilterOption } from '../../redux/models/common/ui';

const moment = extendMoment(Moment as any);

enum reportViews {
    TRANSACTIONS = 'transactions',
    PROVIDER_TOTALS = 'providers'
};

const TransactionsComponent = (props: any) => {
    const classes = CommonStyles();
    const [expandedIndexes, setExpandedIndexes] = React.useState<boolean[]>([]);
    const [expandedDetails, setExpandedDetails] = React.useState<any>({});
    const [userSettings] = React.useState<any>(JSON.parse(localStorage.getItem('userSettings') || '{}'));
    const [dispensedReportData, setDispensedReportData] = React.useState<any>(null);
    const [reportView, setReportView] = React.useState<reportViews>(reportViews.TRANSACTIONS);
    const [userTimezone, setUserTimezone] = React.useState<any>(null);
    const [filterToSave, setFilterToSave] = React.useState<any>(null);

    const handleTransactionsSearch = () => {
      const filter = generateFiltersList()
      if (!filter) {
        return
      }

      props.getTransactions(filter);
    }

    /*
    * top level export of all data on page from filter bar
    */
    const handleTransactionsExport = () => {
      const filter = generateFiltersList(true);
      
      if (!filter) {
        return
      }

      const userTimezoneProp = props.userProperties.find((property: any) => {
        return property.propertyKey === 'TIMEZONE'
      });

      const tz = userTimezoneProp?.propertyValue || moment.tz.guess();
      let allColumns = [
            {
                label: "Provider",
                name: "provider",
                options: {
                    download: false,
                    setCellHeaderProps: () => setCellHeaderProps('provider')
                },

            },
            {
                label: "Provider Name",
                name: "providerName",
                options: {
                    display: false,
                    viewColumns: false,
                    setCellHeaderProps: () => setCellHeaderProps('providerName')
                }
            },
            {
                label: "Provider NPI",
                name: "providerNpi",
                options: {

                    viewColumns: false,
                    setCellHeaderProps: () => setCellHeaderProps('providerNpi'),
                    display: false
                }
            },
            {
                label: "Customer",
                name: "customer",
                options: {
                    setCellHeaderProps: () => setCellHeaderProps('customer')
                }
            },
            {
                label: "Address",
                name: "address1",
                options: {
                    display: false,
                    viewColumns: false,
                    setCellHeaderProps: () => setCellHeaderProps('address1')
                }
            },
            {
                label: "Address 2",
                name: "address2",
                options: {
                    display: false,
                    viewColumns: false,
                    setCellHeaderProps: () => setCellHeaderProps('address2')
                }
            },
            {
                label: "City",
                name: "city",
                options: {
                    setCellHeaderProps: () => setCellHeaderProps('city'),
                    viewColumns: false,
                    display: false
                }
            },
            {
                label: "State",
                name: "state",
                options: {
                    display: false,
                    setCellHeaderProps: () => setCellHeaderProps('state'),
                    viewColumns: false
                }
            },
            {
                label: "Zip Code",
                name: "zip",
                options: {
                    setCellHeaderProps: () => setCellHeaderProps('zip'),
                    display: false,
                    viewColumns: false,
                }
            },
            {
                label: "Cabinet Type",
                name: "cabinetType",
                options: {
                    setCellHeaderProps: () => setCellHeaderProps('cabinetType'),
                    customBodyRender: formatCabinetTypeForReport
                }
            },
            {
                label: "Product Ndc",
                name: "ndc",
                options: {
                    setCellHeaderProps: () => setCellHeaderProps('ndc')
                }
            },
            {
                label: "Product Description",
                options: {
                    setCellHeaderProps: () => setCellHeaderProps('productName')
                },
                name: "productName"
            },
            {
                options: {
                    setCellHeaderProps: () => setCellHeaderProps('total')
                },
                label: "Total Dispensed",
                name: "total"
            },
        ]
      const colNames: any[] = [];

      allColumns.forEach((c: any) => {
        colNames.push(c.name);
      });

      //report is named 'SUP_UIEXPORT_UsageReport' even tho file name is "transactions"
      const params = constructEnqueueParams(
        'SUP_UIEXPORT_UsageReport',
        props.authUser?.record.email,
        filter,
        {columnsRequested: colNames},
        tz
      );

      props.sendOneTimeReportRequest(params);
    }

    /*
    * used by handleTransactionsSearch and export button
    */
    const generateFiltersList = (exportReport: boolean = false) => {
      // setValidationRequired(true);
      if (!props.selectedSupplier || !props.selectedDateRange) {
        return;
      }
      // setValidationRequired(false);
      const filterToSave: any = {};
      const filterValues = props.selectedFilterValues || {};
      const selectedProductsArray = filterValues['PRODUCT_NAME'] || undefined;

      const dateData: any = props.selectedDateRange;

      // filterToSave.supplierId = props.selectedSupplier?.id || undefined;
      // filterToSave.supplierCode = props.selectedSupplier?.supplierCode || undefined;
      // filterToSave.product = selectedProductsArray || undefined;
      // filterToSave.dateRange = dateData || undefined;
      // setFilterToSave(filterToSave); //this is confusing and should be refactored RM

      const timezone = props.userTimezone || 'UTC';

      const startDate = moment(dateData.startDate).tz(timezone).startOf('day');
      const startDateUtc = moment.utc(startDate).format('YYYY-MM-DDTHH:mm:ss');
      const endDate = moment(dateData.endDate).tz(timezone).endOf('day');
      const endDateUtc = moment.utc(endDate).format('YYYY-MM-DDTHH:mm:ss');
      const dateRangeString = `${startDateUtc},${endDateUtc}`;
      const customerFilter = filterValues['CUSTOMER_NAME'] || undefined;
      const idnFilter = filterValues['IDN'] || undefined;
      const gpoFilter = filterValues['GPO'] || undefined;
      const classOfTradeFilter = filterValues['CLASS_OF_TRADE'] || undefined;
      const facilityTypeFilter = filterValues['FACILITY_TYPE'] || undefined;
      const providerIdFilter = filterValues['PROVIDER_ID'] || undefined;
      const regionFilter = filterValues['REGION'] || undefined;
      
      return {
        supplierId: props.selectedSupplier?.supplierCode,
        dates: dateRangeString,
        ...(regionFilter ? { regionName: regionFilter } : {}),
        ...(selectedProductsArray?.length ? { productGroups: selectedProductsArray.map((productGroup: any) => productGroup.productGroup).join(',') } : {}),
        ...(customerFilter ? { customer: customerFilter } : {}),
        ...(idnFilter ? { idnName: idnFilter } : {}),
        ...(gpoFilter ? { gpoName: gpoFilter } : {}),
        ...(classOfTradeFilter ? { classOfTradeDescription: classOfTradeFilter } : {}),
        ...(facilityTypeFilter ? { facilityTypeDescription: facilityTypeFilter } : {}),
        ...(providerIdFilter ? { provider: providerIdFilter } : {}),
        ...(exportReport ? 
            { 
              exportOptions: JSON.stringify(
                {
                  view: 'transactions', 
                  timezone: props.userTimezone || 'UTC', 
                  showProviders: userSettings?.showProviderData || false
                }
              ) 
            } 
            : 
            {}
          )
      }
    }

    const handleReportViewChange = (event: any, newValue: reportViews) => {
        if (newValue === reportView) return
        setReportView(newValue)
        setExpandedIndexes(props.transactions.map(() => false))
    }

    const setCellHeaderProps = (id: string) => {
        return {
            id: id
        };
    }

    const getTableColumns = (
      showShippingData: boolean,
      showProviders: boolean,
      reportView: string,
      productTransactions: any[]
    ) => {
      let columns = [
        {
          label: "Provider",
          name: "provider",
          options: {
            download: showProviders,
            setCellHeaderProps: () => setCellHeaderProps("provider"),
          },
        },
        {
          label: "Training Status",
          name: "trainingStatus",
          options: {
            download: showProviders,
            setCellHeaderProps: () => setCellHeaderProps("trainingStatus"),
          },
        },
        {
          label: "Training Date",
          name: "trainingDate",
          options: {
            display: false,
            viewColumns: true,
            download: showProviders,
            setCellHeaderProps: () => setCellHeaderProps("trainingStatus"),
          },
        },
        {
          label: "Customer",
          name: "customer",
          options: {
            setCellHeaderProps: () => setCellHeaderProps("customer"),
          },
        },
        {
          label: "Class of Trade",
          options: {
            display: productTransactions.filter((transaction: any) => transaction.classOfTradeDescription ? true : false).length > 0,
            setCellHeaderProps: () =>
              setCellHeaderProps("classOfTradeDescription"),
          },
          name: "classOfTradeDescription",
        },
        {
          label: "Facility Type",
          options: {
            display: productTransactions.filter((transaction: any) => transaction.facilityTypeDescription ? true : false).length > 0,
            setCellHeaderProps: () =>
              setCellHeaderProps("facilityTypeDescription"),
          },
          name: "facilityTypeDescription",
        },
        {
          label: "GPO",
          name: "gpoName",
          options: {
            display: productTransactions.filter((transaction: any) => transaction.gpoName ? true : false).length > 0,
            viewColumns: true,
            setCellHeaderProps: () => setCellHeaderProps("gpoName"),
          },
        },
        {
          label: "IDN",
          name: "idnName",
          options: {
            display: productTransactions.filter((transaction: any) => transaction.idnName ? true : false).length > 0,
            viewColumns: true,
            setCellHeaderProps: () => setCellHeaderProps("idnName"),
          },
        },
        {
          label: "Address",
          name: "address1",
          options: {
            display: false,
            viewColumns: false,
            setCellHeaderProps: () => setCellHeaderProps("address1"),
          },
        },
        {
          label: "Address 2",
          name: "address2",
          options: {
            display: false,
            viewColumns: false,
            setCellHeaderProps: () => setCellHeaderProps("address2"),
          },
        },
        {
          label: "City",
          name: "city",
          options: {
            setCellHeaderProps: () => setCellHeaderProps("city"),
            viewColumns: false,
            display: false,
          },
        },
        {
          label: "State",
          name: "state",
          options: {
            display: false,
            setCellHeaderProps: () => setCellHeaderProps("state"),
            viewColumns: false,
          },
        },
        {
          label: "Zip Code",
          name: "zip",
          options: {
            setCellHeaderProps: () => setCellHeaderProps("zip"),
            display: false,
            viewColumns: false,
          },
        },
        {
          label: "Cabinet Type",
          name: "cabinetType",
          options: {
            display: productTransactions.filter((transaction: any) => transaction.cabinetType ? true : false).length > 0,
            setCellHeaderProps: () => setCellHeaderProps("cabinetType"),
            customBodyRender: formatCabinetTypeForReport,
          },
        },
        {
          label: "Product Ndc",
          name: "ndc",
          options: {
            setCellHeaderProps: () => setCellHeaderProps("ndc"),
          },
        },
        {
          label: "Product Description",
          options: {
            setCellHeaderProps: () => setCellHeaderProps("productName"),
          },
          name: "productName",
        },
      ];

      if (reportView === "transactions") {
        columns.splice(12, 2);
        columns.push({
          options: {
            setCellHeaderProps: () => setCellHeaderProps("lot"),
          },
          label: "Lot",
          name: "lot",
        });
        columns.push({
          options: {
            setCellHeaderProps: () => setCellHeaderProps("qty"),
          },
          label: "Qty",
          name: "qty",
        });
        columns.push({
          options: {
            setCellHeaderProps: () => setCellHeaderProps("timestamp"),
          },
          label: "Date",
          name: "timestamp",
        });
      }

      if (showShippingData) {
        columns.push({
          options: {
            setCellHeaderProps: () => setCellHeaderProps("shippingCity"),
          },
          label: "Shipping City",
          name: "shippingCity",
        });
        columns.push({
          options: {
            setCellHeaderProps: () => setCellHeaderProps("shippingState"),
          },
          label: "Shipping State",
          name: "shippingState",
        });
        columns.push({
          options: {
            setCellHeaderProps: () => setCellHeaderProps("shippingZip"),
          },
          label: "Shipping Zip",
          name: "shippingZip",
        });
      }

      if (!showProviders) {
        columns.splice(0, 3);
      }

      if (reportView === "providers") {
        columns.push({
          options: {
            setCellHeaderProps: () => setCellHeaderProps("total"),
          },
          label: "Total Dispensed",
          name: "total",
        });
      }

      return columns;
    };

    React.useEffect(() => {
      if (props.transactions) {
        generateSalesReport();
      }
    }, [props.transactions]);

    React.useEffect(() => {
      const userTimezoneProp = props.userProperties.find((property: any) => {
        return property.propertyKey === "TIMEZONE";
      });
      setUserTimezone(userTimezoneProp?.propertyValue || moment.tz.guess());
    }, [props.userProperties]);

    React.useEffect(() => {
      props.getFilterData({
        filterList: [
          {
            name: "CLASS_OF_TRADE",
            params: props.selectedSupplier
              ? { supplierId: props.selectedSupplier.supplierCode }
              : {},
          },
          {
            name: "FACILITY_TYPE",
            params: props.selectedSupplier
              ? { supplierId: props.selectedSupplier.supplierCode }
              : {},
          },
        ],
        callback: (responseUrl: string, responseData: any) => {
          switch (responseUrl) {
            case "classOfTradeOptions":
              props.setClassOfTradeOptions(responseData);
              break;
            case "facilityTypeOptions":
              props.setFacilityTypeOptions(responseData);
              break;
          }
        },
      });
    }, [props.selectedSupplier]);

    const generateSalesReport = () => {
      setDispensedReportData(
        props.transactions && props.transactions.length > 0
          ? props.transactions
          : null
      );
      setExpandedIndexes(props.transactions.map(() => false));
    };

    const formatReportData = (customerProduct: any) => {
      const tableData: any = cloneDeep(customerProduct);
      if (reportView === reportViews.PROVIDER_TOTALS) {
        return tableData.providers.map((provider: any) => {
          provider.address1 = customerProduct.address1;
          provider.address2 = customerProduct.address2;
          provider.city = customerProduct.city;
          provider.state = customerProduct.state;
          provider.zip = customerProduct.zip;
          provider.providerName =
            provider.provider === "UNKNOWN"
              ? "UKNOWN"
              : provider.provider.split(" (")[0];
          provider.providerNpi =
            provider.provider === "UNKNOWN"
              ? "UKNOWN"
              : provider.provider.split(" (")[1].replace(")", "");
          return provider;
        });
      }
      if (reportView === reportViews.TRANSACTIONS) {
        return tableData.transactions.map((transaction: any) => {
          transaction.timestamp = moment
            .utc(transaction.timestamp, "YYYY-MM-DDTHH:mm:ssZ")
            .tz(userTimezone)
            .format("YYYY-MM-DD");
          return transaction;
        });
      }
    };

    const getExpandedReportTable = (
      index: number,
      data: any[],
      tableColumns: any[],
      pageOptions: any
    ) => {
      const table = (
        <div style={{ width: "100%" }}>
          <MUIDataTable
            data={data}
            columns={tableColumns}
            options={{
              rowsPerPageOptions: pageOptions,
              search: false,
              filter: false,
              selectableRows: "none",
            }}
          />
        </div>
      );

      setExpandedDetails({ ...expandedDetails, [index]: table });
    };

    return (
      <>
        <GlobalFiltersComponentContainer
          pageName="USAGE_REPORT"
          executeSearch={handleTransactionsSearch}
          doExport={handleTransactionsExport}
          onReset={() => {
            props.resetAllFilters();
          }}
        />

        {dispensedReportData ? (
          <Grid container>
            <Grid item xs={12}>
              {userSettings.showProviderData && (
                <div>
                  <Tabs
                    value={reportView}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleReportViewChange}
                    aria-label="cabinet inventory tabs"
                  >
                    <Tab
                      value={reportViews.TRANSACTIONS}
                      label={"All Transactions"}
                    />
                    <Tab
                      value={reportViews.PROVIDER_TOTALS}
                      label={"Provider Totals"}
                    />
                  </Tabs>
                  <div style={{ minHeight: 10 }}></div>
                </div>
              )}
              {dispensedReportData.map((product: any, index: number) => (
                <Accordion
                  key={index}
                  expanded={expandedIndexes[index]}
                  onChange={() => {
                    expandedIndexes[index] = !expandedIndexes[index];
                    setExpandedIndexes(expandedIndexes);
                    getExpandedReportTable(
                      index,
                      formatReportData(product),
                      getTableColumns(
                        product.customerId === "NFWB",
                        userSettings.showProviderData,
                        reportView,
                        product.transactions
                      ),
                      [10, 20, 30, 50, 100]
                    );
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.expansionHeading}>
                      {product.customerProduct.split(" / ")[0]}
                      <br />
                      {product.customerProduct.split(" / ")[1]}
                      <br />
                      {`GPO: ${product.gpoName || "N/A"}`}
                      <br />
                      {`IDN: ${product.idnName || "N/A"}`}
                      <br />
                      {product.address1} {product.address2} {product.city},{" "}
                      {product.state} {product.zip}
                    </Typography>
                    <Typography className={classes.expansionSecondary}>
                      Total Dispensed: {product.total}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {expandedDetails[index] ? expandedDetails[index] : <></>}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        ) : (
          <EmptyContent message="Select the filter criteria above to view sales report information."></EmptyContent>
        )}
      </>
    );
}

const mapStateToProps = (state: any) => ({
    authUser: state.user.authUser,
    userProperties: state.user.user_properties || [],
    isLoading: state.ui.isLoading,
    selectedSupplier: state.ui?.selectedSupplier || null,
    transactions: state.transaction.list,
    suppliersList: state.supplier?.suppliersList?.result || [],
    selectedExpiryDate: state.ui.selectedExpiryDate || null,
    selectedDateRange: state.ui.selectedDateRange || null,
    selectedFilterValues: state.ui.selectedFilterValues || {}
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
  getTransactions: (data: any) => dispatch(TransactionActions.getTransactions(data)),
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
  setClassOfTradeOptions: (data: string[]) => dispatch(TenantsActions.setClassOfTradeOptions(data)),
  setFacilityTypeOptions: (data: string[]) => dispatch(TenantsActions.setFacilityTypeOptions(data)),
  getFilterData: (data: FilterOption[]) => dispatch(UIAction.getFilterData(data)), 
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionsComponent);





