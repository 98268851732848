import React from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import { DashboardStyles } from "../../hooks/styles";
import BasePage from "../common/base";
import { Constants } from "../../constants/Constants";
import CurrentInventoryComponent from "../inventory/currentInvenotry.component";
import GlobalFiltersComponentContainer from "../../components/globalFiltersComponent";
import { UIAction, InventoryActions, UserActions } from "../../redux/actions";
import MUIDataTable from "mui-datatables";
import { List } from "../../constants/Constants";
import Moment from "moment";
import { extendMoment } from "moment-range";
import {
  CustomDateFormat,
  getSelectedSupplier,
  SanitizeArray,
} from "../../hooks/functions";
import EmptyContent from "../../components/emptyContent";
import { constructEnqueueParams } from "../../helpers/report-queue.helper";
const moment = extendMoment(Moment as any);

const todayDate = moment();
const dateRangesList = [
  {
    label: "Next 7 days",
    value: "Next_7_days",
    date: moment.range(todayDate.clone(), todayDate.clone().add(7, "days")),
  },
  {
    label: "Next 15 days",
    value: "Next_15_days",
    date: moment.range(todayDate.clone(), todayDate.clone().add(15, "days")),
  },
  {
    label: "Next 30 days",
    value: "Next_30_days",
    date: moment.range(todayDate.clone(), todayDate.clone().add(30, "days")),
  },
  {
    label: "Next 60 days",
    value: "Next_60_days",
    date: moment.range(todayDate.clone(), todayDate.clone().add(60, "days")),
  },
  {
    label: "Next 180 days",
    value: "Next_180_days",
    date: moment.range(todayDate.clone(), todayDate.clone().add(180, "days")),
  },
  {
    label: "Current Month",
    value: "Current_Month",
    date: moment.range(
      moment().clone().startOf("month").add(1, "days"),
      moment().clone().endOf("month")
    ),
  },
];

const WarehouseInventory = (props: any) => {
  const classes = DashboardStyles();
  //const [filter] = React.useState({} as any);
  const [columnsOrder, setColumns] = React.useState({} as any);
  let [groupByColumnList, setGroupByColumnList] = React.useState([
    "productGroup",
  ] as any);
  const [currentInventoryFetched, setCurrentInventoryFetched] =
    React.useState(false);
  const [pagination, setPaginationData] = React.useState({
    offset: 0,
    limit: 10,
    page: 0,
  });

  const handleInventoryExport = () => {
    const filter: any = {
      fields: {
        productItemId: true,
        productLabelName: true,
        ndc: true,
        lot: true,
        expiration: true,
        city: true,
        state: true,
        postcode: true,
        gpo: true,
        idn: true,
        plant: true,
      },
      where: generateFiltersList(),
    };

    const userTimezoneProp = props.userProperties.find((property: any) => {
      return property.propertyKey === 'TIMEZONE'
    });

    const tz = userTimezoneProp?.propertyValue || moment.tz.guess();

    const params = constructEnqueueParams(
      'SUP_UIEXPORT_WarehouseInventoryReport',
      props.authUser?.record.email,
      filter,
      {columnsRequested: [
        'productLabelName',
        'lot',
         'onHandQty',
         'plant',
         'city',
         'state',
         'expiration'
      ]},
      tz
    );

    props.sendOneTimeReportRequest(params);
  }

  const generateFiltersList = () => {
    const filter: any = {};
    const supplier = props.selectedSupplier;
    const filterValues = props.selectedFilterValues || {};
    const productsArrayForQuery: any[] = [];
    const productsSelected: any[] = filterValues["PRODUCT_NAME"] || [];

    if (productsSelected?.length) {
      productsSelected.forEach((item: any) => {
        productsArrayForQuery.push(item.productGroup);
      });
    }

    filter.supplierId = supplier ? supplier.id : undefined;
    filter.supplierCode = supplier ? supplier.supplierCode : undefined;
    //filter.productItemId = (selectedProduct) ? selectedProduct.productItemId : undefined;
    filter.productGroup = productsArrayForQuery?.length
      ? productsArrayForQuery
      : undefined;
    filter.lot = filterValues["LOT_NUMBER"] || undefined;

    const dateData: any = props.selectedExpiryDate
      ? dateRangesList.find((d) => {
          return d.value === props.selectedExpiryDate;
        })
      : null;

    filter.expiration = dateData?.date
      ? {
          from: `${dateData.date.start.format("YYYY-MM-DD")}`,
          to: `${dateData.date.end.format("YYYY-MM-DD")}`,
        }
      : undefined;

    return filter;
  };

  const handleSearch = () => {
    const filter: any = {
      fields: {
        productItemId: true,
        productLabelName: true,
        ndc: true,
        lot: true,
        expiration: true,
        city: true,
        state: true,
        postcode: true,
        gpo: true,
        idn: true,
        plant: true,
      },
      where: generateFiltersList(),
    };

    if (!props.selectedSupplier) {
      return;
    }

    props.getWarehouseInventory(JSON.stringify(filter));
    setCurrentInventoryFetched(true);
  };

  React.useEffect(() => {
    handleSearch();
  }, [pagination]);

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<BasePage 
      title={Constants.PAGE_TITLE.WAREHOUSE_INVENTORY} 
      drawerOpen={true}
      pageNameKey="warehouseInventory"
    >
			<div className={classes.appBarSpacer}></div>
			<Container maxWidth={false} className={classes.container}>
				<GlobalFiltersComponentContainer
					pageName="WAREHOUSE_INVENTORY"
					executeSearch={handleSearch}
					onReset={() => {
						props.resetAllFilters();
						props.resetExpiryDate();
					}}
					doExport={handleInventoryExport}
				/>
				{!currentInventoryFetched && (
					<EmptyContent message="Select the filter criteria above to view warehouse inventory." />
				)}
				{currentInventoryFetched && (
					<MUIDataTable
						data={props.warehouseInventory}
						columns={[
							{
								label: 'Product',
								name: 'productLabelName',
								options: {
									display: true,
								},
							},
							{
								label: 'NDC',
								name: 'ndc',
								options: {
									display: true,
								},
							},
							{
								label: 'Lot',
								name: 'lot',
								options: {
									display: true,
								},
							},
							{
								label: 'On hand',
								name: 'onHandQty',
								options: {
									display: true,
								},
							},
							{
								label: 'Expiration',
								name: 'expiration',
								options: {
									display: true,
									customBodyRender: (value?: any) => {
										return CustomDateFormat(value, 'YYYY-MM-DD');
									},
								},
							},
							{
								label: 'Warehouse',
								name: 'plant',
								options: {
									display: true,
								},
							},
							{
								label: 'City',
								name: 'city',
								options: {
									display: true,
								},
							},
							{
								label: 'State',
								name: 'state',
								options: {
									display: true,
									//"customBodyRender": {}
								},
							},
							// {
							//   "label": "Customer",
							//   "name": "customerName",
							//   "options": {
							//     "display": true,
							//   }
							// },

						]}
						options={{
							rowsPerPageOptions: List.PageLimitOptions,
							filterType: 'multiselect',
							selectableRows: 'none',
							filter: false,
							search: false,
							download: false,
							// count: props.inventoryListCount,
							pagination: true,
							page: pagination.page,
							rowsPerPage: pagination.limit,
							fixedHeader: true, // This locks the table headers at the top
							tableBodyHeight: tableHeight + 'px', // Set the height for the table body
						}}
					/>
				)}
			</Container>
		</BasePage>
	);
};
const mapStateToProps = (state: any) => ({
  warehouseInventory: state.inventory.warehouseInventory || [],
  selectedSupplier: state.ui.selectedSupplier || null,
  selectedFilterValues: state.ui.selectedFilterValues || {},
  selectedExpiryDate: state.ui.selectedExpiryDate || null,
  suppliersList: state.supplier?.suppliersList?.result || [],
  authUser: state.user.authUser,
  userProperties: state.user.user_properties || [],
  locationList:
    state.location.list && state.location.list[0]
      ? SanitizeArray(state.location.list[0])
      : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  resetExpiryDate: () => dispatch(UIAction.setSelectedExpiryDate(null)),
  getWarehouseInventory: (filter: any) =>
    dispatch(InventoryActions.getWareHouseInventory(filter)),
  getLotLevelInventory: (filter: any) =>
    dispatch(InventoryActions.getLotLevelInventory(filter)),
  clearInventoryList: () => dispatch(InventoryActions.clearInventoryList()),
  setSelectedSupplier: (data: any) =>
    dispatch(UIAction.setSelectedSupplier(data)),
  getRegionInventory: (filter: any) =>
    dispatch(InventoryActions.getRegionGroupInventory(filter)),
  clearRegionInventory: (empty: any) =>
    dispatch(InventoryActions.successRegionGroupInventory(empty)),
  getExportInventory: (filter: any) =>
    dispatch(InventoryActions.getExportInventory(filter)),
  showLoader: (isLoader: boolean) => dispatch(UIAction.showLoader(isLoader)),
  sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseInventory);
