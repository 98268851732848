import { call, put, select } from 'redux-saga/effects';
import firebase from '../../auth/firebase';
import { UserActions,UserManagementActions, UIAction, SaveSearchActions, SupplierActions } from '../actions/index';
import { UserService, SaveSearchService, SupplierService } from '../services';
import AuthUser from '../lib/authUser';
import { UserRecord, Claims } from '../models/user/user';
import { Constants } from "../../constants/Constants";


export interface UserToken{
    token:string;
    uid:string;
}

export function* handleRniAuthToken(action: ReturnType<typeof UserActions.handleRniAuthToken>): any {
	try {
		yield firebase.auth().signOut();
		yield put(UserActions.clearUserState());
		const userCredential = yield firebase.auth().signInWithCustomToken(action.payload.token);
		const idToken = yield userCredential.user.getIdToken();
		const uid = userCredential.user.uid;
		localStorage.setItem('uid', uid);
		localStorage.setItem('id_token', idToken);
		yield put(UserActions.userTokenRequestCompleteAction({ token: idToken, uid: uid }));
		yield put(UserActions.userRecordRequestStartAction());
	} catch (err) {
		console.log('saga error', err);
	}
}

export function* handleRniAuthPageRefresh(action: ReturnType<typeof UserActions.handleRniAuthPageRefresh>): any {
	try {
		const uid = localStorage.getItem('uid');
		const idToken = localStorage.getItem('id_token');
		if (!uid || !idToken) {
			console.log("NO ID TOKEN")
			throw new Error('User is not authenticated.');
		}
		yield put(UserActions.userTokenRequestCompleteAction({ token: idToken, uid: uid }));
		yield put(UserActions.userRecordRequestStartAction());
	} catch (err) {
		console.log('saga error', err);
		window.location.href = '/logout?authError=true';
	}
}

/*
* initial app setup when page is loaded
*/
export function* getUserRecord(action: ReturnType<typeof UserActions.userRecordRequestStartAction>): Generator<any, any, any> {
    const uid:string = yield select((state) => state.user.uid);
    try {
        const userRecord:UserRecord = yield call(UserService.getInstance().getUserRecord, uid);

        const authStorage = localStorage.getItem('id_token');
        if (!authStorage) {
            throw new Error('User is not authenticated');
        }
        const authUser = new AuthUser(userRecord, authStorage);

        console.log('authUser', authUser);

        const ssPayload = JSON.stringify({
            order: ['title asc'],
            where: {
                userEmail: authUser.record.email,
                pageName: {"like": "%SUP:%"}
            }
        });
        
        const {data} = yield call(SaveSearchService.getInstance().fetchAdvanceSearch, ssPayload);
        yield put(SaveSearchActions.setAdvanceSearch(data));

        yield put(UserActions.userRecordRequestCompleteAction(authUser));
        const userClaims:Claims = yield select((state) => state.user.authUser.record.claims);
        const userNav:string[] = yield call(UserService.getInstance().getUserNav, userClaims);
        yield put(UserActions.setUserNav(userNav));

        //get list of suppliers to populate select 
        let suppliers = {data: null}; //loopback demands this format
        const filters = JSON.stringify({"offset":0,"limit":10000,"skip":0,"order":["supplierName asc"],"fields":{"id":true,"supplierName":true,"supplierCode":true}});
        suppliers = yield call(SupplierService.getInstance().getSuppliers, filters);
        yield put(SupplierActions.successSupplierList(suppliers.data));

        //get list of user regions
        let regions = {data: null}
        regions = yield call(SupplierService.getInstance().getRegions)
        yield put(SupplierActions.successRegionsList(regions.data))

        //get and set user report queue
        const updatedQueue: any = yield call(UserService.getInstance().getUserReportQueue);
        const rq = updatedQueue.data?.reportSubscriptions.reportQueue;
        yield put(UserActions.getUserReportQueueSuccess(rq || []));
    } 
    catch (err: any) {
        if (err.response?.status === 401) {
            window.location.href = '/logout';
        }
        console.log('user saga error', err);
    }
}

export function* setupUser(action: ReturnType<typeof UserActions.sendUserSetup>) {
    try {
        yield put(UIAction.showLoader(true));
        yield put(UserManagementActions.userErrorAction(false));
        yield call(UserService.getInstance().setupUser, action.payload);
        window.location.href = '/';
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        const errorBody = err.response.data
        yield put(UserManagementActions.userErrorAction(errorBody));
        console.log('User setup saga error:', errorBody);   
    }
}

export function* createUserProperty(action: ReturnType<typeof UserActions.userPropertiesCreateStartAction>) {
    try {
        yield put(UIAction.showLoader(true));
        yield call(UserService.getInstance().createUserProperty, action.payload);
        yield put(UserActions.getUserPropertiesAction({user_id:action.payload.user_id}));
    } catch (err: any) {
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield put(UIAction.showLoader(false));
        console.log("saga error", err);
    }
}


export function* getUserProperty(action: ReturnType<typeof UserActions.getUserPropertiesAction>) {
    try {
        const { data } = yield call(UserService.getInstance().getUserProperties, action.payload);
        yield put(UserActions.successUserPropertiesAction(data));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        console.log("saga error", err);
    }
}

export function* createReportSubscription(action: ReturnType<typeof UserActions.createReportSubscription>) {
    try { 
 
        const newSubscrObj: object = yield call(UserService.getInstance().createReportSubscription, action.payload);     

        yield put(UserActions.addUserSubscriptionSuccess(newSubscrObj));

        yield put(UserActions.setSnackbarMessage({
          message: 'Subscription creation succeeded.',
          type: 'success'  
        }));
    } 
    catch (err: any) {
        console.log('create Subscription error:', err);
        
        yield put(UserActions.setSnackbarMessage({
          message: `An error occurred sending your report: ${err?.response?.data?.error?.message || 'Unknown error'}`,
          type: 'error'  
        }));
    }
}

export function* deleteReportSubscription(action: ReturnType<typeof UserActions.deleteReportSubscription>) {
    try {

        const deletedSubscrName: string = yield call(UserService.getInstance().deleteReportSubscription, action.payload); //returns name of deleted on success    

        yield put(UserActions.deleteUserSubscriptionSuccess(deletedSubscrName));

        yield put(UserActions.setSnackbarMessage({
          message: 'Subscription deletion succeeded.',
          type: 'success'  
        }));
    } 
    catch (err: any) {
        yield put(UserActions.setSnackbarMessage({
          message: `An error occurred deleting your subscription: ${err?.response?.data || 'Unknown error'}`,
          type: 'error'  
        }));
    }
}

export function* editReportSubscription(action: ReturnType<typeof UserActions.editReportSubscription>) {
    try {       
        const newSubscrObj: object = yield call(UserService.getInstance().editReportSubscription, action.payload);     

        yield put(UserActions.editUserSubscriptionSuccess(newSubscrObj));

        yield put(UserActions.setSnackbarMessage({
          message: 'Subscription edit succeeded.',
          type: 'success'  
        }));
    } 
    catch (err: any) {
        yield put(UserActions.setSnackbarMessage({
          message: `An error occurred editing your subscription: ${err?.response?.data || 'Unknown error'}`,
          type: 'error'  
        }));
    }
}

export function* sendOneTimeReportRequest(action: ReturnType<typeof UserActions.sendOneTimeReport>) {
    try {       
        yield put(UIAction.showLoader(true));
        yield call(
            UserService.getInstance().sendOneTimeReportRequest,
            action.payload
        );

        yield put(UserActions.sendOneTimeReportSuccess());
        yield put(UIAction.showLoader(false));
        const isEnqueue = action.payload.delivery_method === "UserQueue";

        yield put(
            UserActions.setSnackbarMessage({
                message: isEnqueue
                    ? "Your report will be available in your queue soon."
                    : "Your report has been sent.",
                type: "success",
            })
        );

        //if it was enqueued, add one to the current count of reports we are awaiting
        if (isEnqueue) {
            yield put(UserActions.adjustAwaitedQueueItemsCount(1));
        }
        // yield call(UserService.getInstance().sendOneTimeReportRequest, action.payload);     

        // yield put(UserActions.sendOneTimeReportSuccess());

        // yield put(UserActions.setSnackbarMessage({
        //   message: 'Your report has been sent.',
        //   type: 'success'  
        // }));
    } 
    catch (err: any) {
       yield put(UserActions.setSnackbarMessage({
          message: `An error occurred sending your report: ${err?.response?.data || 'Unknown error'}`,
          type: 'error'  
        }));
    }
}

export function* getUserReportQueue(
    action: ReturnType<typeof UserActions.getUserReportQueue>
): any {
    try {
        const numberAwaited = yield select(
            (state) => state.user?.awaitingQueueItemCount
        );

        if (!numberAwaited || numberAwaited < 1) {
            //not awaiting any reports so don't make polling call
            return;
        }

        const oldQueue = yield select(
            (state) => state.user.authUser?.record?.supplierReportQueue
        );

        const updatedQueue = yield call(
            UserService.getInstance().getUserReportQueue
        );

        const rq = updatedQueue.data?.reportSubscriptions?.reportQueue;

        yield put(UserActions.getUserReportQueueSuccess(rq || []));

        const oldCount = oldQueue ? oldQueue.length : 0;
        const newCount = rq ? rq.length : 0;

        if (newCount > oldCount) {
            //decrement awaiting count by difference
            const reportsAdded = newCount - oldCount;
            yield put(UserActions.adjustAwaitedQueueItemsCount(-reportsAdded));

            yield put(
                UserActions.setSnackbarMessage({
                    message:
                        "A new report is available to download in your queue.",
                    type: "success",
                    snackbarAutohide: null,
                })
            );
        }
    } catch (err) {
        console.error(err);
    }
}

export function* updateUserQueueItem(
  action: ReturnType<typeof UserActions.updateUserQueueItem>
): any {
  try {
    const newSubscrObj = yield call(
      UserService.getInstance().updateUserQueueItem,
      action.payload
    );
  } catch (err: any) {
    throw new Error(err);
  }
}


